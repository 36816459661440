import React from "react"
import imgPortadaEbook from "@components/pageEbook/ebooks/ebook7/image/imgPortadaEbook7.png"
import img1 from "@components/pageEbook/ebooks/ebook7/image/imgCard1.svg"
import img2 from "@components/pageEbook/ebooks/ebook7/image/imgCard2.svg"
import img3 from "@components/pageEbook/ebooks/ebook7/image/imgCard3.svg"
import slider1 from "@components/pageEbook/ebooks/ebook7/image/imgSilder1.png"
import slider2 from "@components/pageEbook/ebooks/ebook7/image/imgSilder2.png"
import slider3 from "@components/pageEbook/ebooks/ebook7/image/imgSilder3.png"
import slider4 from "@components/pageEbook/ebooks/ebook7/image/imgSilder4.png"

const title = (
  <>
  <h1 className="titles-underlined-ebook ebook-ventas">
    Guía para elegir al mejor software para tu Call Center
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook">
    Un software de Call Center permite optimizar todas las campañas que estén activas, ya sean outbound o inbound. Esta herramienta perfecciona todos los procesos.  
    <br /> <br />
Esto se debe a que tiene funcionalidades que facilitan la contactabilidad como los marcadores predictivos y progresivos. Por eso, aquí aprenderás a elegir el mejor para tu negocio. 
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
    <br />
    <span> ¡Descuida que te contactaremos solo por cosas importantes!</span>
  </span>
)

const textOne = (
  <p>
 Un software de call center está construido con funcionalidades para facilitar cualquier tipo de comunicación con un usuario.  
 <br /> <br />
Ya sea por voz o chat, una buena plataforma de call center debe estar preparada para soportar cualquier tipo de atención o interacción durante una campaña. 
  </p>
)
const textTwo = (
  <p>
    Un software de call center cloud mejora el proceso de gestión de servicio al cliente, mejora la productividad y le da mayor rentabilidad al negocio. 
    <br /> <br />
Eso hace que todos los procesos de las campañas, sobre todo, de servicio al cliente, tengan una mejor optimización durante una atención. 
  </p>
)
const textThree = (
  <p>
  Enrutamiento de llamadas, facilidad para integrarse a un CRM, asegurar la escalabilidad. Son algunos de los factores a tener en cuenta para un software de call center. 
  </p>
)

const data = {
  start: {
    support: "Ebook | Contact Center",
    title: title,
    description: textDescription,
    classtext: "text-description-ebook-7",
    link: linkForm,
    href: "/ebook-7.pdf",
    brochure: "ebook-guia-para-elegir-software",
    img: imgPortadaEbook,
  },

  description: {
    cards: [
      {
        title: "Qué es un software para Call Center y por qué deberías tener uno ",
        text: textOne,
        photo: img1,
        class: true,
      },
      {
        title: "Por qué elegir un software de call center en la nube",
        text: textTwo,
        photo: img2,
        class: "",
      },
      {
        title: "Qué factores se deben considerar para su elección",
        text: textThree,
        photo: img3,
        class: true,
      },
    ],
  },
  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Reconocer qué significa realmente un software de Call Center.",
      },
      {
        key: 2,
        text: "Entender las razones de adquirir un software para Call Center.",
      },
      {
        key: 3,
        text: "Hacer un checklist de los factores que debes considerar para su elección.",
      },
      {
        key: 4,
        text: "Distinguir cuáles son las 4 mejores plataformas dentro del mercado.",
      },
      {
        key: 5,
        text: "Conocer que factores se deben considerar para su elección.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
      {
        key: 4,
        img: slider4,
      },
    ],
  },
}

export default data
